// src/components/Carousel.js
import React, { useState, useEffect } from 'react';
import './Carousel.css';
import image1 from '../assets/bannerimg1.jpg'; // Imported images
import image2 from '../assets/bannerimg2.jpg';
import image3 from '../assets/bannerimg3.jpg';

const Carousel = () => {
  const images = [image1, image2, image3]; // Store the images in an array

  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto change image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); 

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [images.length]);

  return (
    <div className="carousel">
      <div className="carousel-inner">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Banner ${index + 1}`}
            className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
