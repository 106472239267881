// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Mission from './pages/Mission';
import AboutUs from './pages/AboutUs';
import PrayerRequest from './pages/PrayerRequest';
import Projects from './pages/Projects';
import Donate from './pages/Donate';
import JoinUs from './pages/JoinUs';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/prayer-request" element={<PrayerRequest />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/join-us" element={<JoinUs />} />
      </Routes>
    </Router>
  );
}

export default App;
